/* eslint-disable no-undef */
import React from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import emi_logo_footer from "../../img/emi_logo_footer.svg";

export default ({
  companyLogo,
  singlePosition,
  companyName,
  query,
  style,
  company,
  linkGoBack
}) => {
  return (
    <div>
      <div className="emi-header-container">
        <div className="text-emi-header">Career Page</div>
        <div className="text-emi-header">
          Powered by
          <a href="https://emilabs.ai/" target="_blank" style={{ display: 'flex', marginLeft: '4px' }}>
            <img
              className="emi-logo-header"
              src={emi_logo_footer}
              alt="Emi Logo"
              width={30}
              height='auto'
            />
          </a>
        </div>
      </div>
        <div className="client-logo-container-single-position" style={style && style.clientLogoBackgroundColor? {background: style.clientLogoBackgroundColor}:null}>
          {linkGoBack ? 
          <div className="link-go-back">
            <Link
              className=""
              to={{
                pathname: `/${companyName}`,
                state: query,
              }}
            >
              <p className="link-go-back-text" style={style && style.goBackLinkColor? {color: style.goBackLinkColor}:null}>
                {company.goBackLinkImg ? <img src={company.goBackLinkImg} /> : null}
                Volver a inicio
              </p>
            </Link>
          </div> : null }
          {companyLogo ? (<div className="client-logo-single-position">
            <img
              className="client-logo-image"
              src={companyLogo}
              alt="Company Name"
            />
          </div>) : null}
          
        </div>
      
    </div>
  );
};
